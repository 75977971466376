<template>
  <div class="grid grid-cols-12">
    <div class="col-span-12 md:col-span-3 md:h-90vh" :class="hiddenMenu ? 'hidden sm:block' : ''">
      <div class="md:flex flex-col md:flex-row w-full h-full overflow-auto relative">
        <div class="flex flex-col w-full text-gray-700 pl-1 pr-2 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800 flex-shrink-0 overflow-auto" ref="scrollchat" @scroll="scrollChat">
          <div @click="$modal.show('iniciarConversa')" class="flex justify-center items-center bg-green-700 absolute rounded-full bottom-10 right-7 h-12 w-12 z-10 cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" class="text-white h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
            </svg>
          </div>
          <div class="p-2 mt-1">
            <div class="mb-1" v-if="$store.state.user.admin || $store.state.user.visualizarFiltrosChat">
              <label for="operador" class="block text-sm font-medium text-gray-700">Operador</label>
              <v-select v-model="operadorFind" :options="operadores" label="nome" :reduce="(p) => p._id" name="operador" id="operador" class="mt-1 sm-select"></v-select>
            </div>
            <div class="mb-2" v-if="$store.state.user.admin || $store.state.user.visualizarFiltrosChat">
              <label for="centrocusto" class="block text-sm font-medium text-gray-700">Centro de custo</label>
              <v-select v-model="centrocustoFind" :options="centrocustos" label="nome" :reduce="(c) => c._id" name="centrocusto" id="centrocusto" class="mt-1 sm-select"></v-select>
            </div>
            <div class="mb-2">
              <label for="tag" class="block text-sm font-medium text-gray-700">Etiqueta / Tabulação</label>
              <v-select v-model="tag" :options="tags" label="nome" :reduce="(t) => t._id" name="tag" id="tag" class="mt-1 sm-select"></v-select>
            </div>
            <div class="mb-2">
              <label for="filtrar" class="block text-sm font-medium text-gray-700">Filtrar</label>
              <select
                v-model="filtrar"
                id="filtrar"
                name="filtrar"
                class="mt-1 block w-full py-1 px-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option :value="null">Todas</option>
                <option value="SemRespostas">Sem respostas</option>
              </select>
            </div>
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12 md:col-span-6">
                <label for="data" class="block text-sm font-medium text-gray-700"> de: </label>
                <input type="date" @input="(event) => (data.de = event.target.value)" :value="data.de" class="w-full border border-gray-200 rounded-md text-center px-2 h-9 mb-2" />
              </div>
              <div class="col-span-12 md:col-span-6">
                <label for="data" class="block text-sm font-medium text-gray-700"> até: </label>
                <input type="date" @input="(event) => (data.ate = event.target.value)" :value="data.ate" class="w-full border border-gray-200 rounded-md text-center px-2 h-9 mb-2" />
              </div>
            </div>
            <div class="grid grid-cols-12 gap-1">
              <div class="col-span-12 md:col-span-4 2xl:col-span-3">
                <select v-model="tipo" class="py-1 px-2 h-11 w-full border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  <option value="telefone">Telefone</option>
                  <option value="nome">Nome</option>
                  <option value="cpf">CPF / CNPJ</option>
                  <option value="contrato">Contrato</option>
                </select>
              </div>
              <div class="col-span-12 md:col-span-8 2xl:col-span-9">
                <div class="flex">
                  <input
                    id="search"
                    name="search"
                    class="bg-gray-200 border-2 border-gray-200 w-full text-sm rounded-xl py-2 px-4 text-gray-700 focus:outline-none focus:border-gray-200"
                    v-model="search"
                    placeholder="Buscar"
                    type="text"
                  />
                  <div @click="start" class="flex justify-center items-center bg-primary top-0 right-0 rounded-xl h-11 w-14 ml-1 z-10 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" class="text-white h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="my-2 flex items-center justify-between">
              <label class="font-medium py-2 px-1 text-gray-700 text-sm">Total: {{ totalChats }} - <span class="text-red-500">Sem resposta: </span> {{ semRespostas }} </label>
              <button v-if="$store.state.user.admin" @click="checaFiltrosParaFinalizarConversa()" class="p-1 rounded-md bg-red-600 text-white text-sm font-semibold hover:bg-red-700">Finalizar conversas</button>
            </div>
          </div>
          <div
            class="py-1 px-2 border-b rounded-xl mb-1 relative"
            v-for="item in listChats"
            :key="item._id"
            :class="
              conversasSelecionadas.includes(item._id) || ($route.params.id && $route.params.id.toString() === item._id.toString())
                ? !item.ativo
                  ? 'bg-gray-300'
                  : 'bg-blue-100'
                : !item.ativo
                ? 'bg-gray-100'
                : item.ultimoAResponder === 'Operador'
                ? 'bg-stone'
                : 'bg-green-100'
            "
          >
            <router-link class="w-full" :class="!item.ativo ? 'opacity-50' : ''" @click.native="hiddenMenu = true" :to="`/chats/messages/${item._id}`">
              <div class="grid grid-cols-12 mt-1 mb-2">
                <div class="col-span-2">
                  <img v-if="!item.contato.profilePic" src="@/assets/avatar.png" alt="icon-avatar" class="w-10 rounded-full mt-2" />

                  <img v-if="item.contato.profilePic" :src="item.contato.profilePic" alt="icon-avatar" class="w-10 rounded-full mt-2" />
                </div>
                <div class="col-span-7 ml-2 mt-1">
                  <span class="text-sm font-bold text-black">
                    {{ item.contato.nome ? uppercaseWords(item.contato.nome) : item.contato.telefone }}
                    <span v-if="item.contato.contrato || item.contato.cpf" class="block text-green-600 text-xs_0 font-bold"> {{ item.contato.contrato ? item.contato.contrato : item.contato.cpf }}</span>
                  </span>
                  <span v-if="!item.contato.contrato && !item.contato.cpf" class="block text-gray-500 text-xs_0 font-bold"> Contrato / CPF / CNPJ Não informado </span>
                  <span class="block text-xs font-normal text-black">
                    {{ item.centrocusto && item.centrocusto.nome ? item.centrocusto.nome : "" }}
                  </span>
                  <span class="block text-xs font-normal text-black">
                    {{ item.operador && item.operador.nome ? uppercaseWords(item.operador.nome) : "" }}
                  </span>
                </div>
                <div class="col-span-3 mt-1 justify-self-end">
                  <div class="flex">
                    <span v-show="item.naoLidas" class="mt-1 mx-1 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-green-500 rounded-full">
                      {{ item.naoLidas }}
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6 mr-2"
                      :class="conversasSelecionadas.includes(item._id) ? 'text-blue-400' : ''"
                      @click="selecionaConversa(item)"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122"
                      />
                    </svg>
                    <svg
                      @click="openListarTags(item)"
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-6 h-6"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      :fill="item.contato.tags.length > 0 && item.contato.tags[0].editarCorDaEtiqueta ? hexToRGBA(item.contato.tags[0].corDaEtiqueta, 0.3) : 'none'"
                      :stroke="item.contato.tags.length > 0 && item.contato.tags[0].editarCorDaEtiqueta ? item.contato.tags[0].corDaEtiqueta : 'currentColor'"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z"
                      />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6z" />
                    </svg>
                    <svg @click="openModalMover(item)" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="w-100">
                <span class="text-xs text-gray-400">
                  {{ item.ultimaMensagem ? `${item.ultimaMensagem.substr(0, 45)}${item.ultimaMensagem.length > 45 ? "..." : ""}` : "Mídia" }}
                </span>
                <br />
                <span class="text-xs text-gray-400">
                  {{ item.dataMensagem | moment("DD/MM/YYYY HH:mm") }} <span v-if="item && !!item.quemEncerrou">{{ `Encerrada por: ${item.quemEncerrou}` }}</span>
                </span>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12 md:col-span-9" :class="!hiddenMenu ? 'hidden sm:block' : ''">
      <div class="flex-1 px:2 sm:px-6 justify-between flex flex-col w-full h-92vh md:h-90vh">
        <div v-if="chat" class="flex sm:items-center justify-between py-2 border-b-2 border-gray-200 relative">
          <svg @click="hiddenMenu = false" xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 absolute top-5 right-5 block sm:hidden z-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
          </svg>
          <div class="flex items-center space-x-4 px-1">
            <img v-if="!contact.profilePic || contact.profilePic === 'false'" src="@/assets/avatar.png" alt="icon-avatar" class="w-10 sm:w-16 h-10 sm:h-16 rounded-full" />

            <img v-if="contact.profilePic && contact.profilePic !== 'false'" :src="contact.profilePic" alt="icon-avatar" class="w-10 sm:w-16 h-10 sm:h-16 rounded-full" />

            <div class="flex flex-col leading-tight">
              <div class="text-lg md:text-2xl mt-1 flex flex-col md:flex-row md:items-center">
                <span class="text-gray-700 mr-3 mb-1">{{ contact.nome ? uppercaseWords(contact.nome) : contact.telefone }}</span>
                <span v-if="contact.contrato" class="text-green-600 text-xs md:text-base font-bold md:ml-2">
                  Contrato:
                  <span class="font-normal">{{ contact.contrato }}</span></span
                >
                <span v-if="$store.state.user.lzv2.centrocusto && centrocusto" class="text-gray-700 text-xs md:text-base font-bold md:ml-2">
                  Centro de Custo:
                  <span class="font-normal">{{ centrocusto && centrocusto.nome ? centrocusto.nome : "" }}</span>
                </span>
                <span v-if="operador" class="text-gray-700 text-xs md:text-base font-bold md:ml-2">
                  Operador:
                  <span class="font-normal">{{ operador && operador.nome ? operador.nome : "" }}</span>
                </span>
                <span v-if="!!contact.quemEncerrou" class="text-gray-700 text-xs md:text-base font-bold md:ml-2">
                  Responsável por encerrar a conversa:
                  <span class="font-normal">{{ contact.quemEncerrou }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div id="messages" :class="chat ? 'bg-chat' : ''" class="h-screen flex flex-col p-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
          <div class="chat-message" v-for="(msg, i) in list" :key="i">
            <div class="flex items-end" :class="{ 'justify-end': msg.me }">
              <div
                class="flex flex-col space-y-2 text-sm_3 max-w-xs mx-2"
                :class="{
                  'order-2 items-start': !msg.me,
                  'order-1 items-end': msg.me
                }"
              >
                <div class="max-w-xs">
                  <div
                    :class="{
                      'rounded-br-none': msg.me && i === msg.length - 1,
                      'rounded-bl-none': !msg.me && i === msg.length - 1,
                      'bg-chat-me text-gray-800': msg.me,
                      'bg-white text-gray-800': !msg.me
                    }"
                    class="px-2 py-3 my-2 sm:mt-0 rounded-lg inline-block"
                  >
                    <!-- RESPOSTAS DE MENSAGEM MENCIONADA -->
                    <p class="bg-blue-500 border-2 border-blue-600 text-white p-2 mb-2 rounded-lg" v-if="msg.quotedMsg">
                      <span v-if="msg.quotedType === 'chat'">{{ msg.quotedMsg }}</span>
                      <span v-if="msg.quotedType !== 'chat'">
                        <img v-if="msg.mensagem" style="height: 50px" alt="img" className="rounded mb-2" :src="`data:image/jpeg;base64,${msg.quotedMsg}`" />
                      </span>
                      <br />
                    </p>

                    <!-- CHAT OU CONTATO -->
                    <p v-if="(!msg.type || msg.type === 'chat') && !msg.mensagem.match(/VCARD/im)" v-html="getHtmlFrom(msg.mensagem)"></p>
                    <p v-if="(!msg.type || msg.type === 'vcard') && msg.mensagem.match(/VCARD/im)" v-html="getHtmlFrom(getVcard(msg.mensagem))"></p>

                    <!-- IMAGEM -->
                    <a v-if="msg.type === 'image'" @click="openModalImg(`${api}/upload?mimetype=${msg.mimetype}&filename=${msg.filename}&folder=arquivosWhats`)">
                      <img style="height: 250px" alt="img" className="rounded mb-2" :src="`${api}/upload?mimetype=${msg.mimetype}&filename=${msg.filename}&folder=arquivosWhats`" />
                    </a>

                    <img style="height: 250px" v-if="msg.type === 'image_file'" alt="img" className="rounded mb-2" :src="msg.base64" />

                    <!-- STICKER -->
                    <a v-if="msg.type === 'sticker'" target="blank" :href="`${api}/upload?mimetype=${msg.mimetype}&filename=${msg.filename}&folder=arquivosWhats`">
                      <img style="height: 250px" alt="img" className="rounded mb-2" :src="`${api}/upload?mimetype=${msg.mimetype}&filename=${msg.filename}&folder=arquivosWhats`" />
                    </a>

                    <!-- DOCUMENTOS COM PREVIEW -->
                    <a v-if="msg.type === 'document' && msg.mensagem" target="blank" :href="`${api}/upload?mimetype=${msg.mimetype}&filename=${msg.filename}&folder=arquivosWhats`">
                      <img v-if="msg.message" style="height: 250px" alt="img" className="rounded mb-2" :src="`data:image/jpeg;base64,${msg.message}`" />
                    </a>

                    <!-- LOCALIZAÇÃO -->
                    <a v-if="msg.type === 'location' && msg.message" target="blank" :href="`https://www.google.com/maps?q=${msg.lat},${msg.lng}&z=17&hl=pt-BR`">
                      <img v-if="msg.mensagem" style="height: 100px" alt="img" className="rounded mb-2" :src="`data:image/jpeg;base64,${msg.mensagem}`" />
                    </a>

                    <!-- DOCUMENTOS SEM PREVIEW -->
                    <a v-if="msg.type === 'document' && !msg.mensagem" target="blank" :href="`${api}/upload?mimetype=${msg.mimetype}&filename=${msg.filename}&folder=arquivosWhats&original=${msg.filename}`">
                      <b>Documento:</b>
                      <br />{{ msg.originalname ? msg.originalname : msg.filename }}
                    </a>

                    <a v-if="msg.type === 'document_file' && !msg.message" target="blank" :href="msg.base64">
                      <b>Documento:</b>
                      <br />{{ msg.originalname ? msg.originalname : "" }}
                    </a>

                    <!-- AUDIOS -->
                    <audio controls v-if="msg.type === 'ptt' || msg.type === 'audio'">
                      <source :src="`${api}/upload?mimetype=${msg.mimetype}&filename=${msg.filename}&folder=arquivosWhats`" :type="msg.mimetype" />
                      Your browser does not support the audio element.
                    </audio>

                    <!-- VIDEOS -->
                    <video controls v-if="msg.type === 'video'">
                      <source :src="`${api}/upload?mimetype=${msg.mimetype}&filename=${msg.filename}&folder=arquivosWhats`" :type="msg.mimetype" />
                      Your browser does not support the video element.
                    </video>
                    <br />
                    <span class="text-xs float-right">
                      {{ msg.t | moment("DD/MM/YYYY HH:mm") }}
                      <span v-if="msg.status === 0" class="font-bold">SEM Whatsapp (Número inválido)</span>
                      <span v-if="msg.status === 55" class="font-bold">Limite de envios atingido</span>
                      <span v-if="msg.status === 9" class="font-bold">Não enviado (Erro ao enviar)</span>
                      <svg
                        v-if="msg.status > 2 && msg.status !== 0 && msg.status !== 55 && msg.status !== 9"
                        xmlns="http://www.w3.org/2000/svg"
                        class="inline text-blue-300 h-4 mb-1 ml-1"
                        :fill="msg.status === 3 ? '#272525' : '#0088ff'"
                        viewBox="0 0 256 256"
                      >
                        <rect width="256" height="256" fill="none"></rect>
                        <polyline points="148 84 60 172 16 128" fill="none" :stroke="msg.status === 3 ? '#272525' : '#0088ff'" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
                        <polyline points="240 84 152 172 128.6 148.6" fill="none" :stroke="msg.status === 3 ? '#272525' : '#0088ff'" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
                      </svg>
                      <svg v-if="msg.status && msg.status < 3" xmlns="http://www.w3.org/2000/svg" class="inline text-blue-300 h-4 mb-1 ml-1" fill="#272525" viewBox="0 0 256 256">
                        <rect width="256" height="256" fill="none"></rect>
                        <polyline points="216 72 104 184 48 128" fill="none" stroke="#272525" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
                      </svg>
                      <svg v-if="msg.status === 0 || msg.status === 9 || msg.status === 55" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="inline text-red-600 font-bold h-4 mb-1 ml-1">
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="chat" class="border-t-2 border-gray-200 px-1 py-1 md:px-2 md:py-2 relative">
          <div v-if="menuEmoji" class="absolute bottom-20 left-5 z-10">
            <VEmojiPicker @select="selectEmoji" />
          </div>
          <div>
            <div v-if="obj_chat && obj_chat.ativo" class="relative flex">
              <span class="absolute inset-y-0 flex items-center">
                <label
                  @click="toggleRecording"
                  for="file-audio"
                  class="inline-flex items-center justify-center rounded-full cursor-pointer ml-1 h-9 w-9 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                  :class="{ 'border-2 border-red-400 animate-pulse bg-green-300': isRecording }"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5 text-gray-600">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"></path>
                  </svg>
                </label>
                <!-- <input type="file" class="hidden" id="file-audio" ref="file-audio" v-on:change="handleFileUpload('file-audio')" accept="audio/*" /> -->

                <label
                  @click="menuEmoji = !menuEmoji"
                  for="file-emoji"
                  class="inline-flex items-center justify-center rounded-full cursor-pointer h-9 w-9 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </label>
              </span>
              <textarea
                v-on:input="auto_grow()"
                v-on:keydown.enter.exact="send"
                rows="1"
                v-model="message"
                type="text"
                :placeholder="$t('txt-writeSomething')"
                id="sendtextarea"
                class="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 bg-gray-200 rounded-full overflow-hidden resize-none min-h-41 max-h-97 pl-20 pr-32 py-3"
              />
              <div class="absolute right-0 items-center inset-y-0 sm:flex">
                <label
                  type="button"
                  for="file-document"
                  class="inline-flex items-center justify-center rounded-full cursor-pointer h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6 text-gray-600">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"></path>
                  </svg>
                  <input type="file" class="hidden" id="file-document" ref="file-document" v-on:change="handleFileUpload('file-document')" />
                </label>
                <label
                  type="button"
                  for="file-image"
                  class="inline-flex items-center justify-center rounded-full cursor-pointer h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6 text-gray-600">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                    ></path>
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"></path>
                  </svg>
                  <input type="file" class="hidden" id="file-image" ref="file-image" v-on:change="handleFileUpload('file-image')" accept="image/*,video/*" />
                </label>
                <button @click="send" type="button" class="inline-flex items-center justify-center rounded-full h-12 w-12 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-6 w-6 transform rotate-90">
                    <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                  </svg>
                </button>
              </div>
            </div>
            <div v-else class="relative flex">
              <span class="text-xl text-green-800 font-semibold">Conversa desativada</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal name="iniciarConversa" height="auto" width="700">
      <div class="px-4 py-3 overflow-auto">
        <form>
          <h2 class="text-lg font-bold mb-2">Iniciar conversa</h2>
          <div class="grid grid-cols-12 gap-2 gap-y-2">
            <div class="col-span-12 md:col-span-6">
              <input
                v-model="formConversa.nome"
                type="text"
                name="nome"
                placeholder="Nome"
                id="nome"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div class="col-span-12 md:col-span-6">
              <input
                v-model="formConversa.telefone"
                type="number"
                name="telefone"
                placeholder="Telefone"
                id="telefone"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div v-if="$store.state.user.lzv2.contato_contrato" class="col-span-12 md:col-span-6">
              <input
                v-model="formConversa.contrato"
                type="text"
                name="contrato"
                placeholder="Contrato"
                id="contrato"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div v-if="$store.state.user.lzv2.contato_contrato" class="col-span-12 md:col-span-6">
              <input
                v-model="formConversa.cpf"
                type="text"
                name="cpf"
                placeholder="CPF / CNPJ"
                id="cpf"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div v-if="!$store.state.user.operador || $store.state.user.admin" class="col-span-12 md:col-span-6">
              <label for="operador" class="block text-sm font-medium text-gray-700">Operador</label>
              <select
                v-model="formConversa.operador"
                id="operador"
                name="operador"
                class="mt-2 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option :value="null">Nenhum</option>
                <option v-for="(p, idx) in operadores" :key="idx" :value="p._id">
                  {{ p.nome }}
                </option>
              </select>
            </div>
            <div class="col-span-12 md:col-span-6">
              <label class="block text-sm font-medium text-gray-700">Etiquetas / Tabulações</label>
              <v-select
                id="tags"
                :multiple="true"
                :options="tags"
                v-model="formConversa.tags"
                label="nome"
                placeholder="selecione as etiquetas / tabulações"
                class="custom-select bg-white rounded-md mt-2 sm-select h-10"
              />
            </div>
            <div v-if="$store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('whatsappApiOficial') !== -1" class="col-span-12">
              <label for="numeroWhatsAppOficial" class="block text-sm font-medium text-gray-700">Número</label>
              <select
                v-model="formConversa.numeroWhatsAppOficial"
                @change="filtraTemplatesPorNumero"
                id="numeroWhatsAppOficial"
                name="numeroWhatsAppOficial"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option :value="null">Nenhum</option>
                <option v-for="(n, idx) in numeros" :key="idx" :value="n._id">
                  {{ n.telefone }}
                </option>
              </select>
            </div>
            <div v-if="$store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('whatsappApiOficial') !== -1 && formConversa.numeroWhatsAppOficial" class="col-span-12">
              <label for="template" class="block text-sm font-medium text-gray-700">Template</label>
              <select
                v-model="formConversa.template"
                @change="selectTemplate"
                id="template"
                name="template"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option :value="null">Nenhum</option>
                <option v-for="(t, idx) in templates" :key="idx" :value="t">
                  {{ t.nome }}
                </option>
              </select>
            </div>
            <div v-if="$store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('whatsappApiOficial') !== -1 && formConversa.vartemplate" class="col-span-12" style="max-height: 200px; overflow-y: auto">
              <div v-for="(item, idx) in formConversa.vartemplate" :key="idx">
                <label class="mt-1 block text-sm font-medium text-gray-700">Variável {{ idx + 1 }}</label>
                <input v-model="item.key" type="text" placeholder="Valor variável" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
              </div>
            </div>
            <div class="col-span-12" v-if="$store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('whatsappApiOficial') === -1">
              <div class="grid grid-cols-12">
                <div class="col-span-12 md:col-span-6 place-content-center">
                  <label for="mensagem" class="block text-sm font-medium text-gray-700">Mensagem</label>
                </div>

                <div class="col-span-12 md:col-span-6 justify-self-end">
                  <label class="text-sm font-medium" :class="!usarMensagemCriada && 'text-gray-700 w-full text-right'">
                    Utilizar pré definidas
                    <input type="checkbox" class="ml-1 rounded-md" v-model="usarMensagemCriada" @input="() => (usarMensagemCriada = usarMensagemCriada)" />
                  </label>
                </div>

                <div class="col-span-12 place-content-center flex items-center">
                  <div v-if="usarMensagemCriada" class="w-full">
                    <v-select id="premsg" :options="preMensagens" v-model="newPreMensagem" label="titulo" placeholder="Selecione a mensagem" class="bg-white rounded-md sm-select w-full" />
                  </div>

                  <router-link
                    v-if="usarMensagemCriada && (!$store.state.user.operador || $store.state.user.admin)"
                    :to="`/premensagem`"
                    class="inline-flex justify-center ml-1 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  >
                    +
                  </router-link>
                </div>
              </div>

              <textarea
                v-model="formConversa.mensagem"
                type="text"
                name="mensagem"
                placeholder="Olá tudo bem?"
                id="mensagem"
                rows="3"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              ></textarea>
            </div>
            <div class="col-span-12">
              <button
                @click="novaConversa"
                type="button"
                class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400"
              >
                Enviar
              </button>
            </div>
          </div>
        </form>
      </div>
    </modal>
    <modal name="moverConversa" height="auto">
      <div class="px-3 py-4">
        <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start mb-3">
          <div class="flex space-x-4">
            <label @click="config = 'info'" class="cursor-pointer text-gray-800 text-xs sm:text-base font-bold py-1 sm:py-2" :class="config === 'info' ? 'text-green-700 px-2 rounded-md border border-green-700' : ''">
              Informações
            </label>
            <label @click="config = 'tags'" class="cursor-pointer text-gray-800 text-xs sm:text-base font-bold py-1 sm:py-2" :class="config === 'tags' ? 'text-green-700 px-2 rounded-md border border-green-700' : ''">
              Tabulações / Etiquetas
            </label>
            <label
              @click="config = 'avaliacao'"
              class="cursor-pointer text-gray-800 text-xs sm:text-base font-bold py-1 sm:py-2"
              :class="config === 'avaliacao' ? 'text-green-700 px-2 rounded-md border border-green-700' : ''"
            >
              Avaliação
            </label>
            <label @click="config = 'mover'" class="cursor-pointer text-gray-800 text-xs sm:text-base font-bold py-1 sm:py-2" :class="config === 'mover' ? 'text-green-700 px-2 rounded-md border border-green-700' : ''">
              Mover Conversa
            </label>
          </div>
        </div>
        <div v-show="config === 'avaliacao'">
          <button
            @click="enviarAvaliacao"
            type="button"
            class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400"
          >
            Enviar pesquisa de Avaliação
          </button>
        </div>
        <div v-if="config === 'tags'" class="h-36">
          <v-select
            id="tags"
            @input="finalizarConversaPorTag(form.contato.tags)"
            :multiple="true"
            v-model="form.contato.tags"
            :options="tags"
            label="nome"
            placeholder="selecione as etiquetas / tabulações"
            class="custom-select bg-white rounded-md mt-2 sm-select"
          />
          <div class="grid grid-cols-12 mt-4">
            <div class="col-span-12">
              <button
                @click="saveChat"
                type="button"
                class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                :class="finalizarConversa ? 'bg-red-500 hover:bg-red-600 focus:bg-red-400' : 'bg-green-500 hover:bg-green-600 focus:bg-green-400'"
              >
                {{ finalizarConversa ? "Salvar Etiquetas e Finalizar Conversa" : "Salvar Etiquetas" }}
              </button>
            </div>
          </div>
        </div>
        <div v-show="config === 'info'">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-6">
              <label for="nome" class="block text-sm font-medium">Nome</label>
              <input v-model="form.contato.nome" type="text" name="nome" id="nome" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
            </div>
            <div class="col-span-12 md:col-span-6 opacity-50">
              <label for="telefone" class="block text-sm font-medium">Telefone</label>
              <input
                v-model="form.contato.telefone"
                disabled
                type="number"
                name="telefone"
                id="telefone"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div class="col-span-12 md:col-span-6">
              <label for="contrato" class="block text-sm font-medium">CPF / CNPJ</label>
              <input v-model="form.contato.cpf" type="text" name="contrato" id="contrato" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
            </div>
            <div class="col-span-12 md:col-span-6">
              <label for="contrato" class="block text-sm font-medium">Contrato</label>
              <input v-model="form.contato.contrato" type="text" name="contrato" id="contrato" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
            </div>
            <div class="col-span-12 justify-self-end">
              <button
                @click="saveChat"
                type="button"
                class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400"
              >
                Salvar Conversa
              </button>
            </div>
          </div>
        </div>
        <div v-show="config === 'mover'">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
              <label for="operador" class="block text-sm font-medium text-gray-700">Operador</label>
              <select
                v-model="form.operador"
                id="operador"
                name="operador"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option :value="null">Nenhum</option>
                <option v-for="(p, idx) in operadores" :key="idx" :value="p._id">
                  {{ p.nome }}
                </option>
              </select>
            </div>
            <div class="col-span-6">
              <button
                @click="arquivarConversa(conversasSelecionadas.length > 0)"
                type="button"
                class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-red-400"
              >
                Finalizar Conversa
              </button>
            </div>
            <div class="col-span-6 justify-self-end">
              <button
                @click="moverChat"
                type="button"
                class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400"
              >
                Mover Conversa
              </button>
            </div>
          </div>
          <h3 class="font-bold mb-2 mt-2">Histórico</h3>
          <div class="overflow-auto">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Operador</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Data</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="(item, idx) in form.historico" :key="idx">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex">
                      <div class="text-sm text-gray-500">
                        {{ item.operador && item.operador.nome ? item.operador.nome : "" }}
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex">
                      <div v-if="item.data" class="text-sm text-gray-500">
                        {{ item.data | moment("DD/MM/YYYY HH:mm") }}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <a
            v-if="this.form && this.form.chat && this.form.chat._id"
            :href="`${api}/v1/chatV2/downloadConversa/${this.form.chat._id}?token=${$store.state.token}`"
            role="button"
            type="button"
            class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-400"
          >
            Download Conversa
          </a>
        </div>
      </div>
    </modal>
    <modal name="imgModal" height="auto">
      <div class="w-full">
        <img :src="imgmodal" alt="img_chat" class="w-full" />
      </div>
    </modal>
    <modal name="listarTags" height="auto">
      <div class="px-2 py-3">
        <h1 class="text-xl font-bold">Tabulações / Etiquetas:</h1>
        <div class="flex flex-wrap mt-4 mb-3">
          <div v-for="(item, idx) in tagsList" :key="idx" class="border py-1 px-4 mx-1 my-1 text-sm text-green-700 font-semibold rounded-md border-green-700">
            {{ item.nome }}
          </div>
        </div>
      </div>
    </modal>

    <modal name="confirmacaoSenha" height="auto">
      <div class="px-2 py-3">
        <h1 class="text-xl font-bold">Digite usuário e senha para confirmar essa operação:</h1>
        <form @submit.prevent="confereSenha()">
          <input
            :required="true"
            class="my-2 bg-gray-200 border-2 border-gray-200 w-full text-sm rounded-xl py-2 px-4 text-gray-700 focus:outline-none focus:border-gray-200"
            v-model="confirmacao.usuario"
            placeholder="Digite seu email (ex: operador@lz.com)"
            type="text"
          />
          <input
            :required="true"
            class="my-2 bg-gray-200 border-2 border-gray-200 w-full text-sm rounded-xl py-2 px-4 text-gray-700 focus:outline-none focus:border-gray-200"
            v-model="confirmacao.senha"
            placeholder="Digite sua senha"
            type="password"
          />
          <button
            class="my-1 inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400"
          >
            Confirmar
          </button>
        </form>
      </div>
    </modal>

    <modal name="listarTags" height="auto">
      <div class="px-2 py-3">
        <h1 class="text-xl font-bold">Tabulações / Etiquetas:</h1>
        <div class="flex flex-wrap mt-4 mb-3">
          <div v-for="(item, idx) in tagsList" :key="idx" class="py-1 px-4 mx-1 my-1 text-sm text-green-700 font-semibold rounded-md border border-green-700">
            {{ item.nome }}
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import vCard from "vcard-parser";
import moment from "moment";
import { VEmojiPicker } from "v-emoji-picker";

export default {
  name: "Messages",
  components: {
    VEmojiPicker
  },
  data() {
    return {
      moment: moment,
      imgmodal: "",
      finalizarConversa: false,
      hiddenMenu: false,
      menuEmoji: false,
      route: "chatV2",
      list: [],
      conversasSelecionadas: [],
      tags: [],
      confirmacao: {
        senha: "",
        usuario: "",
        filtro: null
      },
      filtrar: null,
      operadores: [],
      data: {
        de: null,
        ate: null
      },
      templates: [],
      numeros: [],
      centrocustos: [],
      tagsList: [],
      bloqueiaScroll: false,
      formConversa: {
        nome: "",
        telefone: "",
        mensagem: "",
        contrato: "",
        numeroWhatsAppOficial: null,
        template: null,
        vartemplate: [],
        tags: [],
        operador: null
      },
      config: "info",
      form: {
        chat: null,
        operador: null,
        contato: {
          nome: "",
          telefone: "",
          contrato: "",
          cpf: ""
        }
      },
      message: "",
      total: 0,
      contact: false,
      listChats: [],
      chat: false,
      obj_chat: null,
      operador: null,
      operadorFind: null,
      centrocustoFind: null,
      centrocusto: null,
      tipo: "telefone",
      search: "",
      tag: null,
      searchChat: "",
      totalChats: 0,
      totalQuePodeSerFinalizado: 0,
      semRespostas: 0,
      limit: 30,
      isRecording: false,
      mediaRecorder: null,
      audioChunks: [],
      audioUrl: null,
      audioBlob: null,
      mediaStream: null,
      usarMensagemCriada: false,
      preMensagens: [],
      newPreMensagem: ""
    };
  },

  watch: {
    newPreMensagem(value) {
      console.log(value.mensagem);
      this.formConversa.mensagem = value.mensagem;
    }
  },

  methods: {
    auto_grow() {
      const el = document.getElementById("sendtextarea");
      el.style.height = "5px";
      el.style.height = el.scrollHeight + "px";
    },
    selectEmoji(emoji) {
      this.message += emoji.data;
      this.menuEmoji = false;
    },
    openListarTags(chat) {
      this.tagsList = chat.contato.tags;
      this.$modal.show("listarTags");
    },
    openModalImg(url) {
      this.imgmodal = url;
      this.$modal.show("imgModal");
    },
    finalizarConversaPorTag(tags) {
      if (this.finalizarConversa) {
        this.finalizarConversa = !this.finalizarConversa;
      }

      if (!tags) {
        this.finalizarConversa = false;
        return;
      }

      if (tags.length > 0) {
        tags.forEach((tag) => {
          if (tag.etiquetaParaFinalizarConversas) {
            this.finalizarConversa = true;
            return;
          }
        });
      }
    },
    getVcard(str) {
      const parsed = vCard.parse(str);
      return `*CONTATO*
              ${parsed.n[0].value[1]} ${parsed.n[0].value[0]}
              ${parsed.tel[0].value}`;
    },

    selecionaConversa(chat) {
      if (this.conversasSelecionadas.includes(chat._id)) {
        const index = this.conversasSelecionadas.indexOf(chat._id);
        this.conversasSelecionadas.splice(index, 1);

        return;
      }

      if (this.conversasSelecionadas.length === 10) {
        this.$vToastify.error("Número máximo de conversas selecionadas.");
        return;
      }

      this.conversasSelecionadas.push(chat._id);
    },

    getHtmlFrom(str) {
      if (str) {
        return str
          .replace(/(?:\*)([^*]*)(?:\*)/gm, "<strong>$1</strong>")
          .replace("{#", "<b>Contato: </b>")
          .replace("#}", "")
          .replace(/(?:_)([^_]*)(?:_)/gm, "<i>$1</i>")
          .replace(/(?:~)([^~]*)(?:~)/gm, "<strike>$1</strike>")
          .replace(/\n/im, "<br/>")
          .replace(/(?:```)([^```]*)(?:```)/gm, "<tt>$1</tt>");
      } else {
        return str;
      }
    },

    hexToRGBA(hex, alpha) {
      hex = hex.replace(/^#/, "");

      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }

      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);

      if (isNaN(r) || isNaN(g) || isNaN(b)) {
        return "none";
      }

      alpha = Math.min(1, Math.max(0, alpha));

      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    },

    scrollToBottom() {
      setTimeout(() => {
        const container = this.$el.querySelector("#messages");
        container.scrollTop = container.scrollHeight;
      }, 200);
    },
    async scrollChat() {
      if (this.bloqueiaScroll) {
        this.bloqueiaScroll = false;
        return;
      }

      if (Math.abs(this.$refs.scrollchat.scrollHeight - this.$refs.scrollchat.clientHeight - this.$refs.scrollchat.scrollTop) < 20 && this.limit < this.totalChats) {
        this.limit += 15;
        const busca = this.search.replace("(", "").replace(")", "");
        const listReqChats = await this.$http.post(`/v1/${this.route}/list`, {
          limit: this.limit,
          tipo: this.tipo,
          busca,
          tag: this.tag,
          operador: this.operadorFind,
          filtrar: this.filtrar,
          centrocusto: this.centrocustoFind,
          de: this.data.de,
          ate: this.data.ate
        });
        this.listChats = listReqChats.data.data;
        this.totalChats = listReqChats.data.total;
      }
    },

    async checaFiltrosParaFinalizarConversa() {
      const filtro = {
        tipo: this.tipo,
        busca: this.search.replace("(", "").replace(")", ""),
        operadorFind: this.operadorFind,
        centrocustoFind: this.centrocustoFind,
        tag: this.tag,
        filtrar: this.filtrar,
        de: this.data.de,
        ate: this.data.ate
      };

      const naoPodeEncerrar = !filtro.tag && !filtro.centrocustoFind && !filtro.operadorFind && !filtro.de && !filtro.ate && !filtro.filtrar && (!filtro.busca || filtro.busca.trim() === "");

      if (naoPodeEncerrar) {
        return this.$vToastify.error("Não é possível encerrar todas as conversas sem aplicar algum filtro!");
      }

      const listReqChats = await this.$http.post(`/v1/${this.route}/list`, {
        tipo: filtro.tipo,
        busca: filtro.busca,
        tag: filtro.tag,
        operador: filtro.operadorFind,
        filtrar: filtro.filtrar,
        centrocusto: filtro.centrocustoFind,
        de: filtro.de,
        ate: filtro.ate
      });

      this.listChats = listReqChats.data.data;
      this.totalChats = listReqChats.data.total;
      this.semRespostas = listReqChats.data.semRespostas;
      this.totalQuePodeSerFinalizado = this.listChats.filter((chat) => chat.ativo).length;

      if (this.totalQuePodeSerFinalizado < 1) {
        return this.$vToastify.error("Não existem conversas a serem finalizadas");
      }

      this.confirmacao.filtro = filtro;
      this.$modal.show("confirmacaoSenha");
    },

    async confereSenha() {
      if (this.confirmacao.usuario !== this.$store.state.user.email) {
        return this.$vToastify.error("Email ou senha inválidos!");
      }

      const response = await this.$http.post("/confirmaSenhaLZV2", { usuario: this.confirmacao.usuario, senha: this.confirmacao.senha });

      if (!response.data.success) {
        return this.$vToastify.error("Email ou senha inválidos!");
      }

      await this.$modal.hide("confirmacaoSenha");
      await this.encerrarTodasAsConversas();
    },

    async encerrarTodasAsConversas() {
      if (confirm(`Deseja mesmo encerrar todas as conversas? \nNúmero de conversas a serem encerradas: ${this.totalQuePodeSerFinalizado}.\n\nConfira os filtros!`)) {
        const filtro = this.confirmacao.filtro;
        this.confirmacao.senha = "";
        this.confirmacao.usuario = "";

        const response = await this.$http.post(`/v1/${this.route}/encerrar-conversas`, {
          tipo: filtro.tipo,
          busca: filtro.busca,
          tag: filtro.tag,
          operador: filtro.operadorFind,
          filtrar: filtro.filtrar,
          centrocusto: filtro.centrocustoFind,
          de: filtro.de,
          ate: filtro.ate,
          quantidade: this.totalChats
        });

        if (!response.data.success) {
          return this.$vToastify.error(response.data.err);
        }

        await this.start();

        return this.$vToastify.success("Conversas encerradas com sucesso!");
      }

      return;
    },

    async getMensagens(params) {
      let id = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : "";
      if (!id && params && params.id) id = params.id ? params.id.match(/^[0-9a-fA-F]{24}$/)[0] : "";
      if (id) {
        const listReq = await this.$http.get(`/v1/${this.route}/getMensagens/${id}`);
        this.obj_chat = listReq.data.chat;
        this.list = listReq.data.mensagens;
        this.contact = { ...listReq.data.contato, quemEncerrou: listReq.data.chat.quemEncerrou || "" };
        this.chat = id;
        if (listReq.data && listReq.data.chat && listReq.data.chat.centrocusto) {
          this.centrocusto = listReq.data.chat.centrocusto;
        }
        if (listReq.data && listReq.data.chat && listReq.data.chat.operador) {
          this.operador = listReq.data.chat.operador;
        }
        this.total = listReq.data.mensagens.length;

        const idx = this.listChats.map((el) => el._id).indexOf(this.chat.toString());
        if (idx !== -1) {
          this.listChats[idx] = listReq.data.chat;
          this.$set(this.listChats, idx, { ...this.listChats[idx], naoLidas: 0 });
        }

        this.scrollToBottom();
        return listReq.data.chat;
      }
    },
    async start() {
      const busca = this.search.replace("(", "").replace(")", "");
      const listReqChats = await this.$http.post(`/v1/${this.route}/list`, {
        limit: this.limit,
        tipo: this.tipo,
        busca,
        tag: this.tag,
        operador: this.operadorFind,
        filtrar: this.filtrar,
        centrocusto: this.centrocustoFind,
        de: this.data.de,
        ate: this.data.ate
      });

      this.listChats = listReqChats.data.data;
      this.totalChats = listReqChats.data.total;
      this.semRespostas = listReqChats.data.semRespostas;
    },
    async handleFileUpload(ref) {
      if (!this.$refs[ref].files[0]) return;

      let fd = new FormData();

      fd.append("folder", "arquivosWhats");
      fd.append("file", this.$refs[ref].files[0]);

      const resp = await this.$http.post(`/upload`, fd, {
        headers: { "Content-Type": "multipart/form-data" }
      });

      const file = resp.data.file;
      if (!file) {
        this.$vToastify.error("Selecione um arquivo");
        return;
      }

      const sendFile = await this.$http.post(`/v1/${this.route}/send`, {
        chat: this.chat,
        arquivo: file
      });

      if (sendFile.data.err) return this.$vToastify.error(sendFile.data.err);

      this.$refs[ref].value = null;

      const chat = await this.getMensagens();

      const idx = this.listChats.map((el) => el._id).indexOf(this.chat.toString());
      if (idx !== -1) {
        this.listChats[idx] = chat;
        this.listChats.sort((a, b) => {
          return new Date(b.dataMensagem) - new Date(a.dataMensagem);
        });
      }
    },
    async send() {
      if (!this.message) return;

      const req = await this.$http.post(`/v1/${this.route}/send`, {
        chat: this.chat,
        mensagem: this.message
      });

      if (req.data.msg) {
        this.message = "";

        if (this.list) this.list.push(req.data.msg);
        this.scrollToBottom();

        const idx = this.listChats.map((el) => el._id).indexOf(this.chat.toString());
        if (idx !== -1) {
          this.listChats[idx] = req.data.chat;
          this.listChats.sort((a, b) => {
            return new Date(b.dataMensagem) - new Date(a.dataMensagem);
          });
        }

        const el = document.getElementById("sendtextarea");
        el.style.height = "50px";
        return;
      }

      this.$vToastify.error(req.data.err);

      const el = document.getElementById("sendtextarea");
      el.style.height = "50px";
    },
    async novaConversa() {
      if (!this.formConversa.telefone) return this.$vToastify.error("Digite o telefone");
      if (!this.formConversa.mensagem && this.$store.state.user.acessos_lzv2 && this.$store.state.user.acessos_lzv2.indexOf("whatsappApiOficial") === -1) return this.$vToastify.error("Digite a mensagem");

      const req = await this.$http.post(`/v1/${this.route}/novaConversa`, this.formConversa);
      if (req.data.success) {
        this.$vToastify.success("Enviado com sucesso!");
        this.$modal.hide("iniciarConversa");

        this.formConversa.nome = "";
        this.formConversa.telefone = "";
        this.formConversa.mensagem = "";
        this.formConversa.contrato = "";
        this.formConversa.cpf = "";
        this.formConversa.operador = null;
        this.formConversa.tags = [];

        this.start();
      } else {
        this.$vToastify.error(req.data.err);
      }
    },
    async filtraTemplatesPorNumero() {
      if (this.formConversa.numeroWhatsAppOficial === null) {
        this.templates = [];
        return;
      }

      const responseTemplates = await this.$http.post("/v1/templates/1/list", { ativo: true, status: "Aprovado", numero: this.formConversa.numeroWhatsAppOficial, apenasTexto: true });
      this.templates = responseTemplates.data.list;
    },
    selectTemplate() {
      this.formConversa.vartemplate = [];
      for (let i = 0; i < this.formConversa.template.body.placeholders; i++) {
        this.formConversa.vartemplate.push({ key: "" });
      }
    },
    async openModalMover(chat) {
      let historico = [];
      if (chat.historico) {
        const historyReq = await this.$http.get(`/v1/${this.route}/historico/${chat._id}`);
        historico = historyReq.data.slice(0, 5);
      }
      this.form = {
        chat,
        operador: chat.operador && chat.operador._id ? chat.operador._id : null,
        contato: chat.contato,
        historico: historico
      };
      this.$modal.show("moverConversa");
    },
    async arquivarConversa(usarArrayDeConversas, finalizarPorTags = false) {
      if (!this.form.chat && !usarArrayDeConversas) return this.$vToastify.error("Erro ao encontrar chat");

      if (finalizarPorTags) {
        const _id = this.form.chat._id;
        const quemEncerrou = this.$store.state.user.nome;

        const req = await this.$http.post(`/v1/${this.route}/arquivar`, { _id, quemEncerrou });

        if (!req.data.success) {
          this.$vToastify.error(req.data.err);
          return;
        }

        this.$vToastify.success("Finalizado com sucesso!");
        this.$modal.hide("moverConversa");

        return;
      }

      if (!usarArrayDeConversas && confirm("Tem certeza que deseja finalizar está conversa?")) {
        const _id = this.form.chat._id;
        const quemEncerrou = this.$store.state.user.nome;

        const req = await this.$http.post(`/v1/${this.route}/arquivar`, { _id, quemEncerrou });

        if (!req.data.success) {
          this.$vToastify.error(req.data.err);
          return;
        }

        this.$vToastify.success("Finalizado com sucesso!");
        this.$modal.hide("moverConversa");
        this.closeChat();

        return;
      }

      if (usarArrayDeConversas && this.conversasSelecionadas.length > 0) {
        if (confirm("Uma ou mais conversas foram marcadas para finalização em lote, deseja continuar?")) {
          const erros = [];

          if (this.form.chat._id) this.conversasSelecionadas.push(this.form.chat._id);

          const quemEncerrou = this.$store.state.user.nome;

          this.conversasSelecionadas.forEach(async (conversa, index) => {
            const _id = conversa;
            const response = await this.$http.post(`/v1/${this.route}/arquivar`, { _id, quemEncerrou });

            if (!response.data.success) {
              erros.push(`Houve o(s) seguinte(s) erro(s): ${response.data.err}, para o chat número ${index + 1}`);
            }
          });

          if (erros.length > 0) {
            let mensagem = "";
            erros.forEach((erro) => (mensagem += `${erro}\n`));

            this.$vToastify.error(mensagem);
            return;
          }

          this.conversasSelecionadas = [];
          this.$vToastify.success("Finalizado com sucesso!");
          this.$modal.hide("moverConversa");
          this.closeChat();
        }
      }
    },

    async enviarAvaliacao() {
      if (!this.form.chat) return this.$vToastify.error("Erro ao encontrar chat");

      const req = await this.$http.post(`/v1/operador/avaliacao`, {
        chat: this.form.chat,
        operador: this.form.chat.operador
      });

      if (req.data.success) {
        let link = `https://v2.bestmessage.com.br/avaliacao/${req.data.data._id}`;
        if (this.$store.state.user.lzv2.cliente_trestto) {
          link = `https://uno.trestto.com.br/avaliacao/${req.data.data._id}`;
        }

        const reqSend = await this.$http.post(`/v1/${this.route}/send`, {
          chat: this.form.chat,
          mensagem: `*ATENDIMENTO FINALIZADO*\n\nAvalie nosso atendimento acessando:\n${link}\n\nPara quaisquer outras dúvidas entre em contato novamente obrigado.`
        });

        this.message = "";
        if (reqSend.data.msg) {
          if (this.list) this.list.push(reqSend.data.msg);
          this.scrollToBottom();
        } else {
          this.$vToastify.error(reqSend.data.err);
        }

        this.$vToastify.success("Enviado com sucesso!");
        this.$modal.hide("moverConversa");
      } else {
        this.$vToastify.error(req.data.err);
      }
    },
    async saveChat() {
      if (!this.form.chat) return this.$vToastify.error("Erro ao encontrar chat");

      const req = await this.$http.post(`/v1/${this.route}/saveInfo`, {
        _id: this.form.chat._id,
        contato: this.form.contato
      });

      if (!req.data.success) {
        return this.$vToastify.error(req.data.err);
      }

      this.$modal.hide("moverConversa");
      this.$vToastify.success("Salvo com sucesso!");
      this.config = "info";

      const tags = this.form.contato.tags;
      const finalizarConversa = tags.find((tag) => tag.etiquetaParaFinalizarConversas === true);

      if (finalizarConversa) {
        this.arquivarConversa(false, true);
      }

      this.closeChat();
    },

    async moverChat() {
      if (!this.form.operador) return this.$vToastify.error("Selecione um operador");
      if (!this.form.chat) return this.$vToastify.error("Erro ao encontrar chat");

      if (this.conversasSelecionadas.length === 0 || (this.conversasSelecionadas.length === 1 && this.conversasSelecionadas.includes(this.form.chat._id))) {
        const req = await this.$http.post(`/v1/${this.route}/moverOperador`, {
          _id: this.form.chat._id,
          operador: this.form.operador
        });

        if (!req.data.success) {
          this.$vToastify.error(req.data.err);
          return;
        }

        this.$vToastify.success("Salvo com sucesso!");
        this.$modal.hide("moverConversa");
        this.config = "info";
        this.closeChat();
        return;
      }

      if (this.conversasSelecionadas.length > 0) {
        if (confirm("Uma ou mais conversas estão selecionadas, deseja mover todas?")) {
          const erros = [];

          if (this.form.chat._id) this.conversasSelecionadas.push(this.form.chat._id);

          this.conversasSelecionadas.forEach(async (conversa) => {
            const _id = conversa;
            const response = await this.$http.post(`/v1/${this.route}/moverOperador`, {
              _id,
              operador: this.form.operador
            });

            if (!response.data.success) {
              erros.push(response.data.err);
              return;
            }
          });

          if (erros.length > 0) {
            let mensagem = "";
            erros.forEach((erro) => (mensagem += `${erro}\n`));
            this.$vToastify.error(mensagem);

            return;
          }

          this.$vToastify.success("Salvo com sucesso!");
          this.$modal.hide("moverConversa");
          this.config = "info";
          this.closeChat();

          return;
        }
      }
    },
    customLabel({ name, phone }) {
      return `${name ? `${name} - ` : ""}${phone ? phone : ""}`;
    },
    async closeChat() {
      this.$router.push({ path: `/chats` });
      const busca = this.search.replace("(", "").replace(")", "");

      const listReqChats = await this.$http.post(`/v1/${this.route}/list`, {
        limit: this.limit,
        tipo: this.tipo,
        busca,
        tag: this.tag
      });

      this.listChats = listReqChats.data.data;
      this.totalChats = listReqChats.data.total;
      this.semRespostas = listReqChats.data.semRespostas;

      this.chat = false;
      this.contact = false;
      this.list = [];
      this.total = 0;
    },

    async toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        await this.startRecording();
      }
    },

    async startRecording() {
      try {
        this.audioChunks = [];
        this.mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.mediaRecorder = new MediaRecorder(this.mediaStream);

        this.mediaRecorder.ondataavailable = (event) => {
          this.audioChunks.push(event.data);
        };

        this.mediaRecorder.onstop = () => {
          this.audioBlob = new Blob(this.audioChunks, { type: "audio/ogg" });
          this.audioUrl = URL.createObjectURL(this.audioBlob);

          this.stopMicrophone();
          this.sendAudio();
        };

        this.mediaRecorder.start();
        this.isRecording = true;
      } catch (err) {
        console.error("Erro ao iniciar a gravação:", err);
      }
    },

    stopRecording() {
      this.mediaRecorder.stop();
      this.isRecording = false;
      this.stopMicrophone();
    },
    stopMicrophone() {
      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach((track) => track.stop());
        this.mediaStream = null;
      }
    },
    async sendAudio() {
      if (!this.audioBlob) return;

      try {
        const formData = new FormData();
        formData.append("file", this.audioBlob);
        formData.append("folder", "arquivosWhats");

        // const file = new File([this.audioBlob], `grava-${moment().format("x")}.webm`, { type: this.audioBlob.type });

        const response = await this.$http.post(`/upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" }
        });

        const fileresp = response.data.file;

        const sendFile = await this.$http.post(`/v1/${this.route}/send`, {
          chat: this.chat,
          arquivo: fileresp
        });

        if (sendFile.data.err) return this.$vToastify.error(sendFile.data.err);

        const chat = await this.getMensagens();

        const idx = this.listChats.map((el) => el._id).indexOf(this.chat.toString());
        if (idx !== -1) {
          this.listChats[idx] = chat;
          this.listChats.sort((a, b) => {
            return new Date(b.dataMensagem) - new Date(a.dataMensagem);
          });
        }
      } catch (err) {
        console.error("Erro ao enviar o áudio:", err);
      }
    }
  },

  async beforeRouteUpdate(to, from, next) {
    next();
    await this.getMensagens();
  },
  async beforeRouteLeave(to, from, next) {
    next();
    await this.getMensagens(to.params);
  },
  async beforeMount() {
    await this.start();
    await this.getMensagens();

    const operadoresReq = await this.$http.post("/v1/operador/list", { all: true, chatAcesso: true });
    this.operadores = operadoresReq.data.data;

    const centrocustoReq = await this.$http.post("/v1/centrocusto/list", { all: true });
    this.centrocustos = centrocustoReq.data.data;

    const tagReq = await this.$http.post("/v1/tag/list", { all: true });
    this.tags = tagReq.data.data;

    const responseNumeros = await this.$http.post("/v1/numeros/list", { ativo: true });
    this.numeros = responseNumeros.data.list;

    const responsePreMensagens = await this.$http.post("/v1/preMensagem/list");

    this.preMensagens = responsePreMensagens.data.data;

    console.log("premsg", this.preMensagens);
  },

  mounted() {
    if (!this.$store.state.user || !this.$store.state.user._id) return;

    const id_usuario = !this.$store.state.user.operador ? this.$store.state.user._id.toString() : this.$store.state.user.usuario.toString();

    this.sockets.subscribe(`msgV2-${id_usuario}`, async function (msg) {
      if (
        (!this.$store.state.user.operador && msg.usuario && msg.usuario._id.toString() !== this.$store.state.user._id.toString()) ||
        (this.$store.state.user.operador && msg.usuario && msg.usuario._id.toString() !== this.$store.state.user.usuario.toString())
      )
        return;

      if (msg.chat.toString() === this.chat.toString()) {
        this.bloqueiaScroll = true;
        this.list.push(msg);
        this.scrollToBottom();
      }

      const idx = this.listChats.map((el) => el._id.toString()).indexOf(msg.chat.toString());
      if (idx !== -1) {
        this.$set(this.listChats, idx, {
          ...this.listChats[idx],
          dataMensagem: moment().toDate(),
          ultimaMensagem: msg.mensagem ? msg.mensagem : "",
          ultimoAResponder: "Cliente",
          naoLidas: this.listChats[idx].naoLidas + 1
        });
        this.listChats = this.listChats.sort((a, b) => {
          return new Date(b.dataMensagem) - new Date(a.dataMensagem);
        });
      } else {
        if (msg.operador && msg.operador._id.toString() === this.$store.state.user._id.toString()) {
          await this.start();
        }
      }
    });
    this.sockets.subscribe(`msgV2-update-${id_usuario}`, function (msg) {
      if (msg && msg.chat.toString() === this.chat.toString()) {
        const idx = this.list.map((el) => el._id.toString()).indexOf(msg._id.toString());
        if (idx !== -1) {
          this.bloqueiaScroll = true;
          this.$set(this.list, idx, msg);
        }
      }
    });
  },
  beforeDestroy() {
    if (!this.$store.state.user || !this.$store.state.user._id) return;
    const id_usuario = !this.$store.state.user.operador ? this.$store.state.user._id.toString() : this.$store.state.user.usuario.toString();
    this.sockets.unsubscribe(`msgV2-${id_usuario}`);
    this.sockets.unsubscribe(`msgV2-update-${id_usuario}`);
  }
};
</script>

<style scoped>
#menu {
  display: none;
}
</style>
